<template>
  <div>
    <nationality-list-add-new
      :is-add-new-nationality-sidebar-active.sync="isAddNewNationalitySidebarActive"
      :id="id"
      :data="nationalityData"
      @refetch-data="refetchData"
      v-if="ability.can('create', 'nationality') || true"
    >
    </nationality-list-add-new>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">Nationality</h3>
          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search ..."
            />
          </div>
          <b-button variant="primary" class="px-3 mobile-w100" @click="handleCreate">
            <span class="text-nowrap"
              ><feather-icon size="14" icon="PlusIcon" /> Nationality</span
            >
          </b-button>
        </div>
      </div>

      <b-table
        ref="refNationalityTable"
        class="position-relative"
        :items="fetchNationality"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(is_active)="data">
          <div v-if="data.item.is_active == 1" size="18" class="mr-50 text-success">
            Active
          </div>
          <div v-if="data.item.is_active == 0" size="18" class="mr-50 text-secondary">
            Inactive
          </div>
        </template>

        <template #cell(action)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item
              v-if="ability.can('update', 'nationality') || true"
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNationality"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import NationalityStoreModule from "../NationalityStoreModule";
import useNationalityList from "./useNationalityList";
import NationalityListAddNew from "./NationalityListAddNew.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    NationalityListAddNew,
  },
  methods: {
    confirmDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text:
          "Your action is final and you will not be able to retrieve the document type.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("nationality/deleteNationality", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleCreate() {
      this.id = 0;
      this.resetNationalityData();
      this.isAddNewNationalitySidebarActive = true;
    },
    handleEdit(item) {
      this.id = item.id;
      this.nationalityData = { ...item };
      this.isAddNewNationalitySidebarActive = true;
    },
    resetNationalityData() {
      this.nationalityData = {
        id: 0,
        nationality: "",
      };
    },
  },
  data() {
    return {
      id: 0,
      nationalityData: {},
    };
  },
  setup() {
    const SETTING_NATIONALITY_STORE_MODULE_NAME = "nationality";

    if (!store.hasModule(SETTING_NATIONALITY_STORE_MODULE_NAME))
      store.registerModule(SETTING_NATIONALITY_STORE_MODULE_NAME, NationalityStoreModule);

    onUnmounted(() => {
      if (store.hasModule(SETTING_NATIONALITY_STORE_MODULE_NAME))
        store.unregisterModule(SETTING_NATIONALITY_STORE_MODULE_NAME);
    });

    const isAddNewNationalitySidebarActive = ref(false);

    const {
      fetchNationality,
      tableColumns,
      perPage,
      currentPage,
      totalNationality,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refNationalityTable,
      refetchData,
      ability,
    } = useNationalityList();

    return {
      //Sidebar
      isAddNewNationalitySidebarActive,

      fetchNationality,
      tableColumns,
      perPage,
      currentPage,
      totalNationality,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refNationalityTable,
      refetchData,
      avatarText,
      ability,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
